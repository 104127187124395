import React, { useContext } from "react"
import { Fade } from "react-slideshow-image"

/* Import Global Context(s) */
import IntroContext from "~context/intro"

/* Import Global Component(s) */
import Image from "~components/image/image"

/* Import SLideshow Style(s) */
import "react-slideshow-image/dist/styles.css"

/* Import Local Style(s) */
import "./slideshow.scss"

const Slideshow = ({ slideshow: { images, delay } }) => {
  const [isIntroDone] = useContext(IntroContext)
  const slidesCount = images.length

  return slidesCount > 1 ? (
    <div className="slideshow">
      <Fade
        duration={delay * 1000}
        transitionDuration={150}
        arrows={false}
        pauseOnHover={false}
        canSwipe={false}
        autoplay={isIntroDone}
      >
        {images.map(({ asset }, index) => (
          (asset && <div className="each-fade" key={asset.id}>
                      <div className="image-container">
                        {<Image image={asset.gatsbyImageData} />}
                        {/* <img src={url} alt="" /> */}
                      </div>
                    </div>)
        ))}
      </Fade>
    </div>
  ) : (images[0].asset !== undefined && images[0].asset !== null) ? (
    <div className="slideshow single-image">
      <Image image={images[0].asset.gatsbyImageData} />
    </div>
  ) : ''
}

export default Slideshow
